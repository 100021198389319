<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard
  Author:
  Author URL:
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <!-- <vx-card class="form-title mb-6 vx-card-np" title="Add Comments"> -->
    <div class="flex flex-wrap justify-between items-center form-title">
      <h4 class>Add Comments</h4>
    </div>
    <div class="vx-col w-full px-5 mb-5 formCommentBox">
      <vs-input
        class="inputx w-full"
        placeholder="Type Something"
        v-model="commentSend.mou_comment"
      />
      <span class="commentSendIcon" @click="addComment">
        <img src="@/assets/images/icons/send-icon.svg" alt />
      </span>
    </div>
    <!-- </vx-card> -->
    <div class="mb-6" v-if="commentsList.length > 0">
      <div class="flex flex-wrap justify-between items-center form-title">
        <h4 class>Comments</h4>
      </div>
      <div class="vx-col w-full">
        <div
          class="d-flex px-5 pb-4 mb-3 border-bottom"
          v-for="(commentRow, commentListIndex) in commentsList"
          :key="commentListIndex"
        >
          <div class="mr-4">
            <img
              v-if="commentRow.user_pic"
              :src="commentRow.user_pic+'?token='+tempToken"
              height="50"
              width="50"
              class="comment-user-icon"
              alt
            />
            <img
              v-else
              src="@/assets/images/icons/user-icon.png"
              height="50"
              width="50"
              class="comment-user-icon"
              alt
            />
          </div>
          <div class="w-100">
            <h5 class="font-weight-bold">{{ commentRow.user_name }}</h5>
            <p class="text-dark">{{ commentRow.mou_comment }}</p>
            <div>
              <span class="text-muted mr-4">
                {{
                commentRow.mou_comment_created_date
                }}
              </span>
              <span
                class="text-primary font-bold cursor-pointer"
                @click="openReplyBox(commentRow, commentListIndex)"
              >Reply</span>
            </div>
            <!-- REPLY INPUT COMMENT BOX -->
            <div
              class="vx-col w-75 formCommentBox replyCommentBox collapsible"
              :class="commentRow.isShowReplyInput === true ? 'collapsed' : ''"
            >
              <div class="my-5">
                <vs-input
                  class="inputx w-full"
                  placeholder="Type Something"
                  v-model="commentRow.new_reply"
                />
                <span
                  class="commentSendIcon"
                  @click="sendCommentReply(commentRow, commentListIndex)"
                >
                  <img src="@/assets/images/icons/send-icon.svg" alt />
                </span>
              </div>
            </div>

            <!-- SHOW COMMENTS -->
            <p
              class="text-primary font-bold cursor-pointer mt-1 mb-4"
              @click="showAllComments(commentListIndex)"
              v-if="commentRow.Reply.length > 0 && !commentRow.showAllReply"
            >See all {{commentRow.Reply.length}} comments</p>

            <!-- HIDE COMMENTS -->
            <p
              class="text-primary font-bold cursor-pointer mt-1 mb-4"
              @click="showAllComments(commentListIndex)"
              v-if="commentRow.Reply.length > 0 && commentRow.showAllReply"
            >Hide comments</p>

            <!-- REPLY COMMENTS -->
            <div class="vx-col w-full" v-if="commentRow.showAllReply">
              <div
                class="d-flex px-5 pb-4 mb-3"
                v-for="(replayRow, replayCommentListIndex) in commentRow.Reply"
                :key="replayCommentListIndex"
              >
                <div class="mr-4">
                  <img
                    v-if="replayRow.user_pic"
                    :src="replayRow.user_pic+'?token='+tempToken"
                    height="50"
                    width="50"
                    class="comment-user-icon"
                    alt
                  />
                  <img
                    v-else
                    src="@/assets/images/icons/user-icon.png"
                    height="50"
                    width="50"
                    class="comment-user-icon"
                    alt
                  />
                </div>
                <div class="w-100">
                  <h5 class="font-weight-bold">{{ replayRow.user_name }}</h5>
                  <p class="text-dark">{{ replayRow.mou_reply_comment }}</p>
                  <div>
                    <span class="text-muted mr-4">
                      {{
                      replayRow.mou_reply_created_date
                      }}
                    </span>
                    <span
                      class="text-primary font-bold cursor-pointer"
                      @click="
                        openReplyToReplyBox(
                          replayRow,
                          commentListIndex,
                          replayCommentListIndex
                        )
                      "
                    >Reply</span>
                  </div>
                  <!-- REPLY COMMENT BOX -->
                  <div
                    class="vx-col w-75 formCommentBox replyCommentBox collapsible"
                    :class="replayRow.isShowReplyInput === true ? 'collapsed' : ''"
                  >
                    <div class="my-4">
                      <vs-input
                        class="inputx w-full"
                        placeholder="Type Something"
                        v-model="replayRow.new_reply"
                      />
                      <span
                        class="commentSendIcon"
                        @click="
                        sendCommentToReply(
                          replayRow,
                          commentListIndex,
                          replayCommentListIndex
                        )
                      "
                      >
                        <img src="@/assets/images/icons/send-icon.svg" alt />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContractService from "@/services/ContractService.js";

export default {
  props: {
    formMouId: Number
  },
  data() {
    return {
      tempToken:window.localStorage.getItem("TempToken"),
      isActive: false,
      commentSend: {
        mou_comment: "",
        mou_id: ""
      },
      replayComment: "",
      showComments: false,
      showAllCommentsText: true,
      hideAllCommentsText: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      commentsList: []
      // Comments: [
      //   {
      //     mou_comment: "Ajit",
      //     mou_comment_id: "1",
      //     user_id: "1",
      //     user_name: "Ajit maurya",
      //     user_email: "ajit@dsaad",
      //     user_pic: "https://i.picsum.photos/id/600/200/300.jpg",
      //     new_reply: "",
      //     isShowReplyInput: false,
      //     Reply: [
      //       {
      //         mou_reply_comment: "fdsgdsf",
      //         mou_comment_id: "",
      //         mou_id: "",
      //         user_id: "1",
      //         user_name: "Ajit11 maurya",
      //         user_email: "ajit@dsaad",
      //         user_pic: "",
      //         new_reply: "",
      //         isShowReplyInput: false
      //       },
      //       {
      //         mou_reply_comment: "fdgdsg",
      //         mou_comment_id: "",
      //         mou_id: "",
      //         user_id: "1",
      //         user_name: "Ajit22 maurya",
      //         user_email: "ajit@dsaad",
      //         user_pic: "https://i.picsum.photos/id/600/200/300.jpg",
      //         new_reply: "",
      //         isShowReplyInput: false
      //       }
      //     ]
      //   },
      //   {
      //     mou_comment: "Ajit",
      //     mou_comment_id: "1",
      //     user_id: "1",
      //     user_name: "Pratik More",
      //     user_email: "ajit@dsaad",
      //     user_pic: "https://i.picsum.photos/id/600/200/300.jpg",
      //     new_reply: "",
      //     isShowReplyInput: false,
      //     Reply: [
      //       {
      //         mou_reply_comment: "fdsgdsf",
      //         mou_comment_id: "",
      //         mou_id: "",
      //         user_id: "1",
      //         user_name: "Ajit11 maurya",
      //         user_email: "ajit@dsaad",
      //         user_pic: "https://i.picsum.photos/id/600/200/300.jpg",
      //         new_reply: "",
      //         isShowReplyInput: false
      //       },
      //       {
      //         mou_reply_comment: "fdgdsg",
      //         mou_comment_id: "",
      //         mou_id: "",
      //         user_id: "1",
      //         user_name: "Ajit22 maurya",
      //         user_email: "ajit@dsaad",
      //         user_pic: "https://i.picsum.photos/id/600/200/300.jpg",
      //         new_reply: "",
      //         isShowReplyInput: false
      //       }
      //     ]
      //   }
      // ]
    };
  },
  mounted() {
    this.getAllComments();
    eventBus.$on("clearAllComment", e => {
      this.commentsList = [];
    });
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    },
    // TOP COMMENT BOX
    addComment() {
      if (this.commentSend.mou_comment == "") {
        this.$vs.notify({
          title: "Field Required",
          text: "Type something in comment box",
          iconPack: "feather",
          icon: "check_box",
          color: "warning"
        });
        return false;
      }
      this.commentSend.mou_id = this.formMouId;
      this.$vs.loading();
      let payload = {
        ...this.commentSend
      };
      ContractService.addMouFormCommet(payload)
        .then(response => {
          const { data } = response;
          this.$vs.loading.close();
          if (data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            this.getAllComments();
            this.commentSend.mou_comment = "";
          } else {
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        });
    },

    // REPLY COMMENT BOX
    sendCommentReply(item, index) {
      let reply1 = "@" + item.user_name.split(" ")[0] + " ";
      if (reply1 == this.commentsList[index].new_reply) {
        this.$vs.notify({
          title: "Field Required",
          text: "Type something in comment box",
          iconPack: "feather",
          icon: "check_box",
          color: "warning"
        });
        return false;
      }
      let payload = {
        mou_reply_comment: this.commentsList[index].new_reply,
        mou_comment_id: item.mou_comment_id,
        mou_id: this.formMouId
      };
      console.log("payload :>> top reply ", payload);
      ContractService.addMouFormReplyCommet(payload)
        .then(response => {
          const { data } = response;
          this.$vs.loading.close();
          if (data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            this.commentsList[index].new_reply = "";

            this.commentsList[index].new_reply = reply1;
            this.commentsList[index]["Reply"].push(data.data);
          } else {
            this.$vs.notify({
              title: "Error 1",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error 2",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        });
    },

    // REPLY TO REPLY COMMENT BOX
    sendCommentToReply(item, comindex, rowIndex) {
      let reply1 = "@" + item.user_name.split(" ")[0] + " ";
      console.log(reply1, item, "fdsfdsf");
      if (reply1 == this.commentsList[comindex]["Reply"][rowIndex].new_reply) {
        this.$vs.notify({
          title: "warning",
          text: "Type Somthing",
          iconPack: "feather",
          icon: "check_box",
          color: "warning"
        });
        return false;
      }
      let payload = {
        mou_reply_comment: this.commentsList[comindex]["Reply"][rowIndex]
          .new_reply,
        mou_comment_id: item.mou_comment_id,
        mou_id: this.formMouId
      };
      console.log("payload :>> end reply ", payload);
      ContractService.addMouFormReplyCommet(payload)
        .then(response => {
          const { data } = response;
          this.$vs.loading.close();
          if (data.Status) {
            this.commentsList[comindex]["Reply"].push(data.data);
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            this.commentsList[comindex]["Reply"][rowIndex].new_reply = "";
            this.commentsList[comindex]["Reply"][rowIndex].new_reply = reply1;
            // this.commentsList[comindex]["Reply"][rowIndex].isShowReplyInput=false;
          } else {
            this.$vs.notify({
              title: "Error 1",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error 2",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        });
    },

    showAllComments: function(index) {
      //  this.showComments = !this.showComments;
      // console.log(this.commentsList[index], "dsdfdsfds");
      this.commentsList[index]["showAllReply"] = this.commentsList[index][
        "showAllReply"
      ]
        ? false
        : true;
    },

    hideAllComments: function() {
      this.showComments = !this.showComments;
      this.showAllCommentsText = !this.showAllCommentsText;
    },

    openReplyBox: function(item, index) {
      console.log("item :>>&&************ ", item);
      this.commentsList[index].new_reply =
        "@" + item.user_name.split(" ")[0] + " ";
      this.commentsList[index].isShowReplyInput = !this.commentsList[index]
        .isShowReplyInput;
    },
    openReplyToReplyBox: function(item, CommentIndex, replyIndex) {
      console.log("item :>> $$$$$$$$$$$", item);
      this.commentsList[CommentIndex]["Reply"][replyIndex].new_reply =
        "@" + item.user_name.split(" ")[0] + " ";
      this.commentsList[CommentIndex]["Reply"][
        replyIndex
      ].isShowReplyInput = !this.commentsList[CommentIndex]["Reply"][replyIndex]
        .isShowReplyInput;
    },
    getAllComments: function() {
      console.log("this.formMouId :>> get Comments ", this.formMouId);
      ContractService.getComments(this.formMouId)
        .then(response => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.commentsList = data.data;
          } else {
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "Comments",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        });
      // this.commentsList = this.Comments;
    }
  },
  components: {}
};
</script>

<style lang="scss"></style>
